
// const local = "http://localhost/wordpress";
const serveur = "https://back-end.oxton-digital.com";
export const URL_Back = serveur;
export const URL_Dev = "https://oxton-digital.com"
const lien="https://outlook.office365.com/book/DIGISERVICEFORFUN@oxton-digital.com/s/r2PU4vNFNEeV21nViyrqbA2";
export const demo=lien ;

const url_site="https://oxton-digital.com/fr";
export const oxton = url_site ;
export const website = 100 ;